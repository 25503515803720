module.exports = [{
      plugin: require('C:/DEV/Projects/InsightAhead/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":2400}}]},
    },{
      plugin: require('C:/DEV/Projects/InsightAhead/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/DEV/Projects/InsightAhead/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('C:/DEV/Projects/InsightAhead/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
